import '@greendistrict/sdk/styles/base.css';
import React from 'react';
import PageApp from '@greendistrict/sdk/Page/PageApp';
import BrandProvider from '@greendistrict/sdk/Brand/BrandProvider';
import Head from 'next/head';
import { Global, css } from '@emotion/react';
const Page = (props) => {
  return (
    <>
      <Head>
        <link
          href="/fonts/Moderat-Bold.woff2"
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          href="/fonts/Moderat-Regular.woff2"
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      <Global
        styles={css`
          @font-face {
            font-family: 'ModeratBold';
            font-weight: bold, 700, 800, 900;
            font-display: swap;
            src: url('/fonts/Moderat-Bold.woff2') format('woff2'),
              url('/fonts/Moderat-Bold.woff') format('woff'),
              url('/fonts/Moderat-Bold.ttf') format('truetype');
          }

          @font-face {
            font-family: 'ModeratRegular';
            font-display: swap;
            src: url('/fonts/Moderat-Regular.woff2') format('woff2'),
              url('/fonts/Moderat-Regular.woff') format('woff'),
              url('/fonts/Moderat-Regular.ttf') format('truetype');
          }

          * {
            font-family: ModeratRegular;
          }

          h1,
          h2,
          h3,
          h4,
          .font-primary,
          .content-text h1,
          .content-text h2,
          .MuiButtonBase-root {
            font-family: ModeratBold;
          }
        `}
      />
      <BrandProvider
        context={{
          button: {
            rounded: 'full'
          }
        }}
      >
        <PageApp {...props} />
      </BrandProvider>
    </>
  );
};
export default Page;
